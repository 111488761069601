// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/main","parentId":"@@/global-layout","id":"1"},"2":{"path":"/main","parentId":"@@/global-layout","id":"2"},"3":{"path":"/invite","title":{"en":"Come and join the fun!","zh-CN":"快来一起抓马吧","zh-TW":"快來一起抓馬吧"},"parentId":"@@/global-layout","id":"3"},"4":{"path":"/inviteGroup","title":{"en":"Come and join the fun!","zh-CN":"快来一起抓马吧","zh-TW":"快來一起抓馬吧"},"parentId":"@@/global-layout","id":"4"},"5":{"path":"/inviteFriend","title":{"en":"Come and live in the same apartment with me and let's have a party together!","zh-CN":"快来跟我住在同一栋公寓一起开party!","zh-TW":"快來跟我住在同一棟公寓，一起開 party！"},"parentId":"@@/global-layout","id":"5"},"6":{"path":"/intro","parentId":"@@/global-layout","id":"6"},"7":{"path":"/intro-mi","parentId":"@@/global-layout","id":"7"},"8":{"path":"/intro-other","parentId":"@@/global-layout","id":"8"},"9":{"path":"/protocolList","parentId":"@@/global-layout","id":"9"},"10":{"path":"/userProtocol","parentId":"@@/global-layout","id":"10"},"11":{"path":"/privacyPolicy","parentId":"@@/global-layout","id":"11"},"12":{"path":"/subscribeProtocol","parentId":"@@/global-layout","id":"12"},"13":{"path":"/purchaseProtocol","parentId":"@@/global-layout","id":"13"},"14":{"path":"/inviteRule","title":{"en":"Event Rules","zh-CN":"活动规则","zh-TW":"活動規則"},"parentId":"@@/global-layout","id":"14"},"15":{"path":"/*","parentId":"@@/global-layout","id":"15"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__main__index" */'@/pages/main/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__invite__index" */'@/pages/invite/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__inviteGroup__index" */'@/pages/inviteGroup/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__inviteFriend__index" */'@/pages/inviteFriend/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__intro__index" */'@/pages/intro/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__intro-mi__index" */'@/pages/intro-mi/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__intro-other__index" */'@/pages/intro-other/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__protocolList__index" */'@/pages/protocolList/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__userProtocol__index" */'@/pages/userProtocol/index.tsx')),
'11': React.lazy(() => import(/* webpackChunkName: "p__privacyPolicy__index" */'@/pages/privacyPolicy/index.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "p__subscribeProtocol__index" */'@/pages/subscribeProtocol/index.tsx')),
'13': React.lazy(() => import(/* webpackChunkName: "p__purchaseProtocol__index" */'@/pages/purchaseProtocol/index.tsx')),
'14': React.lazy(() => import(/* webpackChunkName: "p__inviteRule__index" */'@/pages/inviteRule/index.tsx')),
'15': React.lazy(() => import(/* webpackChunkName: "p__404__index" */'@/pages/404/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/home/jenkins/agent/workspace/clotho/drama-web/drama-web-pipeline/code-clone/src/layouts/index.tsx')),
},
  };
}
