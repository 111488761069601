import moment from 'moment';

export default {
  setRem: () => {
    document.addEventListener('DOMContentLoaded', function () {
      resetRem();
    });
    window.addEventListener('load', function () {
      setTimeout(resetRem, 300);
    });
    window.addEventListener('resize', function () {
      setTimeout(resetRem, 300);
    });
    function resetRem() {
      const c = document.documentElement;
      const a = c.getBoundingClientRect().width || screen.width || 375;
      const limit540 = false;
      const fz = limit540 ? ~~(a <= 540 ? a : 540) / 3.75 : a / 3.75;
      c.style.fontSize = fz + 'px';
    }
    resetRem();
  },
  getQuery: function () {
    const result = {};
    let hash = window.location.search;
    if (hash === '') {
      return result;
    }
    try {
      if (hash.indexOf('?') > -1) {
        hash = hash.split('?')[1];
      }
      const hashArray = hash.split('&');
      for (let i = 0, len = hashArray.length; i < len; i++) {
        const item = hashArray[i].split('='),
          key = item[0],
          value = decodeURIComponent(item[1]);
        if (!result[key]) {
          result[key] = value;
        } else {
          if (typeof result[key] === 'string') {
            result[key] = [result[key], value];
          } else {
            result[key].push(value);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    return result;
  },

  getPhoneSys: function () {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //i os terminal
    let type;
    if (isAndroid) {
      type = 'Android';
    } else if (isIOS) {
      type = 'IOS';
    }
    return type;
  },

  relativePath2PositionPath: function (hash) {
    const { origin, pathname } = window.location;
    return origin + pathname + hash;
  },

  throttle: (fn = () => {}, wait = 300, triggle = false) => {
    let timeout;
    return (res) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (triggle) {
        const firstClick = !timeout;
        if (firstClick) {
          fn(res);
        }
        timeout = setTimeout(() => {
          timeout = null;
        }, wait);
      } else {
        timeout = setTimeout(() => fn(res), wait);
      }
    };
  },
  str2utf8: (str) => {
    if (window.TextEncoder) {
      const encoder = new TextEncoder();
      const bytes = encoder.encode(str);
      let result = '';
      for (let i = 0; i < bytes.length; i++) {
        result += String.fromCharCode(bytes[i]);
      }
      return result;
    } else {
      return eval("'" + encodeURI(str).replace(/%/gm, '\\x') + "'");
    }
  },
  pxScale: () => {
    const rootFontSize = document.documentElement.style.fontSize || '100px';
    const fontSizeNum = Number(rootFontSize.replace('px', ''));
    return fontSizeNum / 100;
  },
  browser: (userAgent?: any) => {
    let _ua = userAgent || '';
    if (typeof window !== 'undefined') {
      _ua = window.navigator.userAgent;
    }
    const browser: any = {
      isAndroid: /android|Android|Linux/gi.test(_ua),
      isIphone: /iphone/i.test(_ua),
      isWeChat: /micromessenger/gi.test(_ua),
      isQQBrowser: /MQQBrowser/gi.test(_ua),
      isUCBrowser: /UCBrowser/gi.test(_ua),
      isInQQ: / QQ\//gi.test(_ua),
      isMIBrowser: /XiaoMi\/MiuiBrowser/gi.test(_ua),
      isOppoBrowser: /OppoBrowser/gi.test(_ua),
      isVivoBrowser: /VivoBrowser/gi.test(_ua),
      isInDing: /DingTalk/gi.test(_ua),
      isWeibo: /weibo/gi.test(_ua),
    };
    browser.isSafari = !browser.isAndroid && /Mozilla\/.*iPhone.*Mobile.*Safari/gi.test(_ua);
    browser.ua = _ua;
    return browser;
  },
  whatjudgeBrand: function judgeBrand(userAgent?: any) {
    const sUserAgent = userAgent || window.navigator.userAgent.toLowerCase() || '';
    const isIphone = sUserAgent.match(/iphone/i);
    const isHuawei = sUserAgent.match(/huawei/i);
    const isHonor = sUserAgent.match(/honor/i);
    const isOppo = sUserAgent.match(/oppo/i);
    const isVivo = sUserAgent.match(/vivo/i);
    const isXiaomi = sUserAgent.match(/mi\s/i);
    const isXIAOMI = sUserAgent.match(/xiaomi/i);
    const isXiaomi2s = sUserAgent.match(/mix\s/i);
    const isRedmi = sUserAgent.match(/redmi/i);
    const isSamsung = sUserAgent.match(/sm-/i);
    const isOppoRen = sUserAgent.match(/covc/i);
    const isOppoR15 = sUserAgent.match(/pacm00/i);

    if (isIphone) {
      return 'iphone';
    } else if (isHuawei || isHonor) {
      return 'huawei';
    } else if (isSamsung) {
      return 'samsung';
    } else if (isOppo || isOppoR15 || isOppoRen) {
      return 'oppo';
    } else if (isVivo) {
      return 'vivo';
    } else if (isXiaomi || isRedmi || isXiaomi2s || isXIAOMI) {
      return 'xiaomi';
    } else {
      return 'default';
    }
  },
  checkIfMobile: () => {
    const userAgent = navigator.userAgent;
    const mobilePattern = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobilePattern.test(userAgent);
  },
};
