import Tools from '@/utils/tools';
import routesList from '../config/routes';

if (process.env.UMI_ENV !== 'prod') {
  window.VConsole && new window.VConsole();
}

Tools.setRem();

const pathName = window.location.pathname;
const lang = Tools.getQuery()['lang'];
const customTitle = routesList.find((item) => item.path === pathName)?.title;
if (customTitle && lang) {
  document.title = customTitle[lang];
}
