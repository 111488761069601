export default [
  { path: '/', redirect: '/main' },
  { path: '/main', component: 'main' },
  {
    path: '/invite',
    component: 'invite',
    title: {
      en: 'Come and join the fun!',
      'zh-CN': '快来一起抓马吧',
      'zh-TW': '快來一起抓馬吧',
    },
  },
  {
    path: '/inviteGroup',
    component: 'inviteGroup',
    title: {
      en: 'Come and join the fun!',
      'zh-CN': '快来一起抓马吧',
      'zh-TW': '快來一起抓馬吧',
    },
  },
  {
    path: '/inviteFriend',
    component: 'inviteFriend',
    title: {
      en: `Come and live in the same apartment with me and let's have a party together!`,
      'zh-CN': '快来跟我住在同一栋公寓一起开party!',
      'zh-TW': '快來跟我住在同一棟公寓，一起開 party！',
    },
  },
  { path: '/intro', component: 'intro' },
  { path: '/intro-mi', component: 'intro-mi' },
  { path: '/intro-other', component: 'intro-other' },
  { path: '/protocolList', component: 'protocolList' },
  { path: '/userProtocol', component: 'userProtocol' },
  { path: '/privacyPolicy', component: 'privacyPolicy' },
  { path: '/subscribeProtocol', component: 'subscribeProtocol' },
  { path: '/purchaseProtocol', component: 'purchaseProtocol' },
  {
    path: '/inviteRule',
    component: 'inviteRule',
    title: {
      en: 'Event Rules',
      'zh-CN': '活动规则',
      'zh-TW': '活動規則',
    },
  },
  { path: '/*', component: '404' },
];
